$primaryColor: #14B8A6 !default;
$primaryLightColor: #e8f8f6 !default;
$primaryDarkColor: #119C8D !default;
$primaryDarkerColor: #07403a !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #F0FDFA !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import './_variables'; //Lara light default variables
@import './_customize'; // Customizations
@import './_fonts'; //Lara light default variables
@import './_components'; //Lara light default variables
@import './_extensions'; //Lara light default variables
