@import 'assets/layout/styles/layout/layout';

@import '../node_modules/primeicons/primeicons.css';
@import 'assets/layout/styles/theme/cybersort/theme.scss';
@import 'primeng/resources/primeng.css';

@import '../node_modules/primeflex/primeflex';

.leader-line {
  z-index: 0 !important;
}

.loader {
  margin: 50px auto 0;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #14b8a6;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

#CookiebotWidget
  .CookiebotWidget-body
  .CookiebotWidget-body-inner
  .CookiebotWidget-main-logo {
  display: none;
}
